import Model from "./Model";

let Expr = Model.extend({

  data(){
    return {
      // normal data
      id: null,
      ref: Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1) + Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
      text: "",
      lang: "bre",
      translations: [],
      created_at: new Date,
      last_trained_at: null,
      nb_trainings: 0, // nb de entrainements passés pour cette expression
      nb_succedded_trainings: 0, // nb de entrainements passés avec succès pour cette expression
      recent_trainings: [], // résultats des dix derniers entrainements `[0, 0, 1, 1, 1]`
    };
  },

  computed:{
    recent_trainings_success_rate(){
      if ( !this.recent_trainings.length ) return 0;
      return (this.recent_trainings.reduce( (last,current)=>last+current, 0) ) / this.recent_trainings.length;
    },

    priority(){
      let successes_score = 1000 - (this.nb_succedded_trainings||0),
          last_trained_score = new Date(this.last_trained_at).getTime()/100000/3600/24/365;
      return successes_score - last_trained_score;
    }
  },

  watch: {
    // text:(newVal, oldVal) => console.info(`text updated from ${oldVal} to ${newVal}`)
  },

  methods:{

    /**
     * Records the result of a training
     * @param  {Boolean} result true=success, false=failed
     * @return {void}
     */
    train( result ){
      this.nb_trainings++;
      if ( result ) this.nb_succedded_trainings++;
      if ( this.recent_trainings.length >= 10 ){
        this.recent_trainings.splice(0, 1);
      }
      this.recent_trainings.push( result?1:0 );
      this.last_trained_at = new Date();
    },


    toBackup(){
      let data = this.toHash({
        only: `
          id
          text ref lang translations
          created_at last_trained_at
          nb_trainings nb_succedded_trainings recent_trainings
        `
      });
      if ( !data.id ) delete data.id; // Avoid sending {id:null}
      return data;
    }
  },

  created(props = {}){
    // convert strings to dates
    this.created_at = new Date( this.created_at );
    this.last_trained_at = new Date( this.last_trained_at );
  },

});

export default Expr;
