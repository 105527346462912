<template lang="html">
  <div>
    <h3>Choose a game:</h3>
    <p>
      <button
        v-for="game in availableGames"
        @click.prevent="runGame(game)"
        class="button space-right space-under-small"
      >{{ game }}</button>
    </p>
    </ul>
  </div>
</template>

<script>
export default {
  name: "game-selector",

  props: {}, // props

  data(){
    return {
      availableGames: ["default-game", "click-theme-game", "click-translation-game", "mix-letters-game"]
    }
  }, // data

  methods: {
    runGame( gameName ){
      this.$parent.currentGame = gameName;
    }
  }, // methods
}
</script>

<style lang="css">
</style>
