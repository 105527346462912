<template lang="html">
  <div>
    <h3>Edit an expression</h3>

    <div>
      <button href="#" class="button" @click.prevent="close">close <span class="enticon">&times;</span></button>
    </div>

    <div v-if="expr">
      <p>
        <label for="edit-expr-text">Text</label>
        <input id="edit-expr-text" type="text" class="input" v-model="expr.text" placeholder="Text">
      </p>
      <!--
      <p>
        <label for="edit-expr-lang">Lang</label>
        <input id="edit-expr-lang" type="text" class="input" v-model="expr.lang" placeholder="Lang">
      </p>
    -->
      <p>
        <label for="edit-expr-translations">Translations</label>
        <input id="edit-expr-translations" type="text" class="input" v-model="translationsInput" placeholder="Translations">
      </p>
      <p>
        <button
          v-if="!this.exprExists"
          type="button"
          class="button is-success"
          @click="save"
        >
          Create
          <span class="enticon">&plus;</span>
        </button>

        <button
          v-if="this.exprExists"
          type="button"
          class="button is-alert"
          @click="remove(expr)"
        >
          Delete
          <span class="enticon">&times;</span>
        </button>
      </p>
      <!-- <pre style="max-width: 400px;">{{ editedExpr }}</pre> -->
      <!-- <pre style="max-width: 400px;">{{ expr }}</pre> -->
    </div>
    <div v-else>
      initializing...
    </div>
  </div>
</template>

<script>
import Expr from "../Expr";
export default {
  props: {
    editedExpr: {
      default: null
    }
  },

  data(){
    return {
      expr: this.editedExpr,
      translationsInput: ""
    }
  },

  computed: {
    hydra(){ return this.$parent.hydra; },
    thesaurus(){ return this.hydra.thesaurus; },

    // returns either current expression already
    // exists in thesaurus (based on its "ref" data)
    exprExists(){
      return this.thesaurus.some(function(expr){
        return expr.ref == this.expr.ref;
      }.bind(this));
    }
  },

  watch: {
    editedExpr(newVal){
      this.changeEditedExpr(newVal);
    },

    translationsInput(newVal){
      this.expr.translations = newVal
        .split(/,\s{0,}/)
        .map(item=>item.trim())
        .filter(item=>item)
    }
  },

  methods: {
    save(){
      this.hydra.addExpr( this.expr )
    },

    changeEditedExpr(newVal){
        if ( this.expr === null ) this.expr = new Expr;
        else this.expr = newVal;
        this.translationsInput = this.expr.translations.join(', ');
    },

    reset(){
      this.expr = new Expr();
      this.translationsInput = "";
    },

    close(){
      this.$emit('close');
    },

    remove(expr){
      this.$emit('delete', expr);
    }
  },

  created(){
    this.changeEditedExpr(this.editedExpr);
  }
}
</script>
