<template lang="html">
  <div v-if="trainingExpr">
    <h3>Base Game</h3>
    <button href="#" @click.prevent="close">close &times;</button>
    <button href="#" @click.prevent="replay">replay &#10226;</button>
  </div>
</template>


<script>
export default {
  props: {
    thesaurus: {
      default: [],
    }
  }, // props

  data(){
    return {
      trainingExpr: null,
      lastTrainedExpr: null,
    }
  }, // data

  methods: {
    /**
     * Returns the expression with the highest necessity of being trained.
     * if ${number} is provided, an array of expressions is returned, with this number of items.
     * @param  {Integer} number - (optional) number of expressions to return (in an array)
     * @return {Expr|Array}
     */
    pickExpr(number){

      // exclude last trained expression
      let pickable = !this.lastTrainedExpr ? this.thesaurus.slice() : this.thesaurus.filter(function(expr){
        return expr.text == this.lastTrainedExpr.text;
      }.bind(this));

      // return the expr with highest priority
      pickable.sort( (a,b) => b.priority - a.priority );

      if ( number ) return pickable.slice(0, number);
      return pickable[0];
    },

    /**
     * Defines provided ${expr} as current expression
     * @param  {Expr} expr - expression to be trained
     */
    trainExpr(expr){
      this.trainingExpr = expr;
      this.lastTrainedExpr = expr;
    },

    /**
     * Records a training success on training expression
     * @return {[type]} [description]
     */
    success(){
      this.trainingExpr.train(1);
      setTimeout(this.replay, 800);
    },

    /**
     * Records a training failure on training expression
     * @return {[type]} [description]
     */
    failure(){
      this.trainingExpr.train(0);
      setTimeout(this.replay, 800);
    },

    /**
     * Restarts the same game
     * @return {[type]} [description]
     */
    replay(){
      this.$emit('replay');
    },

    /**
     * Closes the game
     * @return {[type]} [description]
     */
    close(){
      this.$emit('end');
    }
  }, // methods
}
</script>
