<template lang="html">
  <base-game-layout v-if="trainingExpr">

    <template slot="title">Click on translation</template>

    <p>
      What is the translation of <strong>{{ trainingExpr.translations.join(', ') }}</strong> ?
    </p>

    <div class="space-under">
      <button
        v-for="choice in choices"
        type="button"
        class="button space-right space-under-small"
        @click="check(choice)"
        :class="choiceClasses(choice)"
      >
        {{ choice.text }}
      </button>
    </div>

    <game-result :result="result" />

  </base-game-layout>
</template>

<script>
import BaseGame from "./BaseGame.vue";
import BaseGameLayout from "./BaseGameLayout.vue";
import GameResult from "../GameResult.vue";

export default {

  name: "click-theme-game",
  extends: BaseGame,

  props: {
    thesaurus: {
      default: [],
    }
  }, // props

  components: {
    BaseGameLayout, GameResult
  },

  data(){
    return {
      trainingExpr: null,
      lastTrainedExpr: null,

      selected: null, // selected translation
      result: 0, // -1=failure ; 0=none ; 1=success
    }
  }, // data

  methods: {
    check(expr){
      if ( !this.selected ){
        let result = expr==this.trainingExpr;
        this.trainingExpr.train( result );
        this.result = result ? 1 : -1;
        this.selected = expr;
        setTimeout(this.replay, 800);
      }
    },

    choiceClasses( choice ){
      let classes = [];
      if ( this.result ) classes.push( choice == this.trainingExpr ? 'is-success' : 'is-danger' )
      if ( this.selected == choice ) classes.push( 'selected-answer' );
      return classes;
    }
  }, // methods

  created(){
    let expressions = this.pickExpr(3);
    this.trainExpr( expressions[0] );
    expressions.sort( (a,b)=>Math.random()-0.5 );
    this.choices = expressions;
  }
}
</script>
