<template>
  <pop-up :type="type" :duration="duration" @close-pop-up="closePopUp">
    <div v-html="message"></div>
  </pop-up>
</template>

<script>
  import PopUp from '../PopUp.vue';

  export default {

    components: {
        "pop-up": PopUp
    },

    name: "standard-notice-pop-up-component",
    props: {
      "type": {
        default: "info", // info|warning|danger|success
        // type: String|null,
      },
      "duration": {
        default: 2500,
        type: Number
      },
      "message": {
        default: "standard notice"
      },
    },

    data(){
      return {
      };
    }, // data

    computed:{}, // computed

    methods: {
      closePopUp(){
        this.$emit("close-pop-up");
      }
    }, // methods

  }
</script>
