import Expr from "./Expr";

export default class Hydra {

  constructor(thesaurus=[]){
    this.thesaurus = []; // {Array} available expressions
    this.lastTrainedExpr = null; // {Object} last trained expression

    this.addExpr(thesaurus);
  }

  // add one or many expressions to thesaurus
  addExpr(expressions){

    if ( !Array.isArray( expressions ) ) expressions = [expressions];

    expressions.forEach(function(expr){
      if ( !(expr instanceof Expr) ) expr = new Expr(expr);
      let index = this.thesaurus.findIndex( item => item.ref == expr.ref );
      if ( index > -1 ) this.thesaurus.splice( index, 1, expr );
      else this.thesaurus.push(expr);
    }.bind(this));
  }

  toBackup(){
    return this.thesaurus.map(expr=>expr.toBackup());
  }

}
