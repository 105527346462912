<template lang="html">
  <div class="whole">
    <header>
      <p>
        <strong>Score: </strong>{{ score }} (potential: {{ maxPossibleScore }})
        <button class="button" @click="persist">persist</button>
      </p>

      <!-- ui notifications -->
      <component :is="currentPopUpComponent" v-bind="currentPopUpProps" @close-pop-up="closePopUp"/>
    </header>
    <main class="flex flex-space-between">
      <div class="pane hide-lt-md">

        <h3>expressions</h3>
        <div>
          <ul>
            <li>
              nb expr: {{ thesaurus.length }}
              <button class="input" @click="editExpr(null)">
                  new &plus;
              </button>
              </li>
            <li>deleted: {{ deletedTexts }}</li>
          </ul>
        </div>
        <hr>
        <div>
          <input class="input" type="text" v-model="search" placeholder="Search"> <button v-if="search" class="input" @click="search=''">&times;</button>
        </div>
        <ul>
          <li
            v-for="expr in thesaurusByFiltered"
            :key="expr.ref"
          >
            <a href="#" @click.prevent="editExpr(expr)">{{ expr.text }}</a>
            <pre class="pre">
              {{ expr.toHash({computed:true}) }}
            </pre>
          </li>
        </ul>
      </div>

      <div class="pane game flex-1">
        <transition :duration="{ enter: 200, leave: 200 }" name="component-fade" mode="out-in">
          <component
          :is="currentGame"
          :thesaurus="thesaurus"
          :key="currentGameKey"
          @replay="replay"
          @end="endGame"
          />
        </transition>
      </div>

      <div v-if="showEditor" class="pane hide-lt-md flex-1">
        <transition :duration="{ enter: 200, leave: 200 }" name="component-fade" mode="out-in">
          <expr-form :key="currentEditorKey" :editedExpr="editedExpr" @close="closeEditor" @delete="deleteExpr"/>
        </transition>
      </div>
    </main>
  </div>
</template>

<style media="screen">
  .current {
    background: #fafafa;
    border: 1px solid green;
  }
</style>
<style media="screen" scoped>
  ul {
    padding-left: 1em;
  }
</style>

<script>

import Hydra from "../Hydra"
import $ from "jquery";

import GameSelector from "./GameSelector.vue"
import ExprForm from "./ExprForm.vue"
import standardNoticePopUpComponent from './PopUpComponents/standardNoticePopUpComponent.vue';

import DefaultGame from "./games/DefaultGame.vue"
import ClickThemeGame from "./games/ClickThemeGame.vue"
import ClickTranslationGame from "./games/ClickTranslationGame.vue"
import MixLettersGame from "./games/MixLettersGame.vue"

export default {
  name: "hydra",

  components: {
    standardNoticePopUpComponent,
    GameSelector,
    DefaultGame, ClickThemeGame, ClickTranslationGame, MixLettersGame,
    ExprForm
  },

  data() {
    return {
      hydra: new Hydra( [] ),
      deletedExpr: [],

      currentGame: "game-selector",
      currentGameKey: 0, // we increment this when whe want to reset a game

      showEditor: false,
      editedExpr: null,
      currentEditorKey: 0, // we increment this when whe want to reset the editor

      currentPopUpComponent: null, // which pop-up compenent to show
      currentPopUpProps: null,

      search: "", // filter for expressions listed
    }
  }, // data

  computed: {
    thesaurus(){
      return this.hydra.thesaurus;
    },
    thesaurusByPriority(){
      return this.thesaurus.slice().sort((a,b) => b.priority - a.priority);
    },
    thesaurusByFiltered(){
      if ( !this.search ) return this.thesaurusByPriority;
      return this.thesaurusByPriority.filter(function(expr){
        let searchable = expr.translations.concat([expr.text]);
        let pattern = new RegExp(this.search);
        return searchable.some(text=>pattern.test(text))
      }.bind(this));
    },

    score(){
      return Math.round( this.thesaurus.reduce( (score, currentExpr)=>{
        return score + currentExpr.recent_trainings.slice(-5).filter(result=>result).length;
      }, 0 ) );

      /*
      let nb_trainings = this.thesaurus.reduce( (score, currentExpr)=>{
        return score + currentExpr.nb_trainings;
      }, 0 );
      let nb_succedded_trainings = this.thesaurus.reduce( (score, currentExpr)=>{
        return score + currentExpr.nb_succedded_trainings;
      }, 0 );
      return Math.round( nb_succedded_trainings / nb_trainings * 10 * this.thesaurus.length );
      */
    },

    maxPossibleScore(){
      return Math.round( this.thesaurus.length * 5 );
    },

    deletedTexts(){
      return this.deletedExpr.map(expr=>expr.text);
    }
  }, // computed

  methods: {
    replay(){
      this.currentGameKey++;
    },

    endGame(){
      this.currentGame = "game-selector"
    },

    editExpr(expr){
      this.currentEditorKey++;
      this.editedExpr = expr;
      this.showEditor = true;
    },

    closeEditor(){
      this.editedExpr = null;
      this.showEditor = false;
    },

    deleteExpr(expr){
      this.deletedExpr.push(expr);
      let index = this.thesaurus.findIndex( item => item.ref == expr.ref );
      this.thesaurus.splice(index, 1);
    },

    // saves the data on the remote server
    persist(){
      let data = {
        thesaurus: this.hydra.toBackup(),
        deleted: this.deletedExpr.filter(expr=>expr.id).map(expr=>expr.ref)
      };
      $.post('/expr', data)
        .then(function(resp){
          if ( !resp.success ) return !this.notify("request failed", "danger");
          if ( !resp.data ) return !this.notify("data missing", "danger");
          if ( !resp.data.thesaurus ) return !this.notify("data.thesaurus missing", "danger");
          if ( !resp.data.deleted ) return !this.notify("data.deleted missing", "danger");
          this.hydra.addExpr( resp.data.thesaurus );
          this.notify("Saved", "success")
        }.bind(this))
        .catch(function(xhr, status, errorMsg){
          console.error(errorMsg);
          this.notify("Save request failed.", "danger")
        }.bind(this))
    },


    /**
     * Closes current popup
     * @return {void}
     */
    closePopUp(){
        this.popUp(null, null);
    },

    /**
     * Activates one of the pop-up components
     * @param  {string|null} popUpName - pop-up to be displayed (its name, without "-pop-up-component" suffix). `null` hides the pop-up
     * @return {void}
     */
    popUp(popUpName, props){
        // console.info("popUp", popUpName, props);
        if ( popUpName ) popUpName += "-pop-up-component";
        this.currentPopUpComponent = popUpName;
        this.currentPopUpProps = props;
        // if ( undefined === this.$options.components[popUpName] ) console.error(`Unknown component "${popUpName}".`);
    },

    /**
     * Opens a standard notification pop-up
     * @param  {String} message - message to be displayed
     * @param  {String} type    - info|warning|danger|success
     * @param  {Integer} duration - time before hiding the notification. 0 means manual hiding only (but is not default).
     * @return {void}
     */
    notify(message, type, duration){
        this.popUp('standard-notice', { message, type, duration });
    },
  }, // methods

  created(){
    // console.info( this);
    this.$on("pop-up", this.popUp);
    // this.$on("close-pop-up", this.closePopUp);
    this.$on("notify", this.notify);
  }, // created

  mounted(){
    $.get('/expr')
      .then(function(resp){
        this.hydra.addExpr( resp );
      }.bind(this))
    .catch(function(){
      this.notify("Failed reading database.", "danger")
    }.bind(this))
  } // mounted

}
</script>
