import Vue from "vue";
import Hydra from "./vue/Hydra.vue";

new Vue({
  el: '#hydra',
  template: '<hydra/>',
  components: { Hydra }
});




/*


import Model from "./Model";

let Person = Model.extend({
  data: function(){
    return {
      name: null,
      surname: null,
      gender: null
    };
  }
});

let Male = Model.extend({
  data: function(){
    return {
      name: null,
      surname: null,
      gender: "male"
    };
  },
  methods:{
    build(){}
  }
});
console.info("Male", Male);

let Female = Model.extend({
  data: function(){
    return {
      name: null,
      surname: null,
      gender: "female"
    };
  },
  methods:{
    shop(){}
  }
});
console.info("Female", Female);

let erwan = {
  name: "Erwan",
  surname: "Lefèvre"
}
erwan = new Male(erwan);
// console.info( "erwan", erwan );
// console.info( "erwan.toHash()", erwan.toHash() );


let nat = {
  name: "Nathalie",
  surname: "Le Mentec"
}
nat = new Female(nat);
// console.info( "nat", nat );
// console.info( "nat.toHash()", nat.toHash() );

let klervi = {
  name: "Klervi",
  surname: "Lefèvre"
}
klervi = new Female(klervi);
klervi.surname = "Bocher";
// console.info( "klervi", klervi );
// console.info( "klervi.toHash()", klervi.toHash() );

console.info( "erwan.toHash()", erwan.toHash() );
console.info( "nat.toHash()", nat.toHash() );
console.info( "klervi.toHash()", klervi.toHash() );

*/
