<template lang="html">
  <div v-if="result" class="notification" :class="['is-'+(result>0?'success':'danger')]">
    <span v-if="result>0">Yeah!</span>
    <span v-else>Crap!</span>
  </div>
</template>

<script>
export default {
  name: "game-result",
  props: {
    result: {
      default: 0, // -1=failure ; 0=none ; 1=success
    }
  }
}
</script>
