<template lang="html">
  <base-game-layout v-if="trainingExpr">

    <template slot="title">Auto train</template>

    <p>
      Do you know how to translate "<strong>{{ trainingExpr.text }}</strong>".
    </p>

    <div v-if="!showTranslation" class="space-under">
      <button type="button" class="button" @click.stop.prevent="showTranslation=true">Check the answer</button>
    </div>

    <div v-else class="space-under">
      <p>Translations: {{ trainingExpr.translations }}</p>
      <button type="button" class="button is-success" @click.stop="check(1)" :class="{'selected-answer':result>0}">I was <strong>right</strong></button>
      <button type="button" class="button is-danger" @click.stop="check(-1)" :class="{'selected-answer':result<0}">I was <strong>wrong</strong></button>
    </div>

    <game-result :result="result" />

  </base-game-layout>
</template>

<script>
import BaseGame from "./BaseGame.vue";
import BaseGameLayout from "./BaseGameLayout.vue";
import GameResult from "../GameResult.vue";

export default {

  extends: BaseGame,

  props: {
    thesaurus: {
      default: [],
    }
  }, // props

  components: {
    BaseGameLayout, GameResult
  },

  data(){
    return {
      showTranslation: false,
      trainingExpr: null,
      lastTrainedExpr: null,
      result: null,
    }
  }, // data

  methods: {
    check( result ){
      if ( !this.result ){
        this.result = result;
        if ( result ) this.success();
        else this.failure();
      }
    }
  }, // methods

  created(){
    this.trainExpr( this.pickExpr() );
  }
}
</script>
