<template>

  <div class="popup-container">
    <div class="popup" :class="['popup-'+(type||'info'), 'popup-'+(type||'info')]">
      <button type="button" class="closeButton" @click.stop="close"><span aria-hidden="true">&times;</span></button>
      <slot>This is a popup</slot>
    </div> <!-- .popup -->
  </div> <!-- .popup-container -->

</template>

<style media="screen" scoped lang="scss">
  @import "../../sass/variables";

  .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: $z-index-notices;
  }

  .popup {
    min-width: 300px;
    max-width: 100vw;

    border: 1px solid #ccc;
    background: white;
    margin: 1em;
    padding: 1em;
    border-radius: .25em;
  }

  .popup-info {
      border-color: $info;
      color: $info;
  }
  .popup-success {
      border-color: $success;
      color: $success;
  }
  .popup-warning {
    border-color: $warning;
    color: $warning;
  }
  .popup-danger {
    border-color: $danger;
    color: $danger;
  }

  .closeButton {
    float: right;
  }
</style>

<script>
  /** For display of pop-ups */
  export default {

    name: "pop-up",
    props: {
      type: {
        default: "info", // info|warning|danger|success
        // type: String|null,
      },
      duration: {
        default: 2500,
        type: Number
      }
    },

    data(){
      return {
      };
    },

    methods: {
      close(){
        this.$emit('close-pop-up');
      }
    },

    mounted(){
      // auto-close after a delay
      if ( this.duration ) setTimeout(this.close, this.duration);
    }

  }
</script>
