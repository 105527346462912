function _each(object, callback){
  for ( let prop in object ) if ( object.hasOwnProperty( prop ) ){
    callback.call( object, object[prop], prop );
  }
}


// ========================================

let modelProto = {
  /**
   * Initialises some data
   * @param {String} dataName     - data name
   * @param {mixed}  initialValue - initial value for data
   */
  $set(dataName, initialValue){

    // define datas with watchers
    Object.defineProperty(this, dataName, {
      get() {
        if (this._computing.length) {
          // record that this property is used for computation
          this._computers[ this._computing[ this._computing.length-1 ] ].push(dataName);
        }
        return this.$data[dataName];
      },
      set(value){

        let oldVal = this.$data[dataName];

        // "don't repeat yourself" : this function triggers the callbacks
        let fireCallbacks = function(value){
          ( this._watchers[dataName] || [] ).forEach( watcher => watcher(value, oldVal) );
        }.bind(this);

        // Arrays need special handling
        // (Thanks to https://stackoverflow.com/a/5100420/4438482)
        if ( Array.isArray(value) ){
          value = new Proxy(value, {
            set: function(target, property, value, receiver) {
              target[property] = value;
              fireCallbacks(target);
              return true;
            },
            apply: function(target, thisArg, argumentsList) {
              let result = thisArg[target].apply(this, argumentList);
              fireCallbacks(target);
              return result;
            },
            deleteProperty: function(target, index) {
              fireCallbacks(target);
              return true;
            },
          });
        }

        this.$data[dataName] = value;

        // check if any of computed properties needs to be updated
        for (let name in this._computers) {
          if (this._computers[name].indexOf(dataName) > -1) {
            this.$compute(name);
          }
        }

        fireCallbacks(value);
      }
    });

    this[dataName] = initialValue;
  },

  /**
   * Updates a computed property
   * @param  {String} name - property to be re-computed
   * @return {void}
   */
  $compute(name) {
    this._computing.push(name);
    this._computers[name] = [];
    this[name] = this.$computed[name].call(this);
    this._computing.pop();
  },


  /**
   * Registers a ${callback} function to be executed whenever data(s)
   * with provided ${dataName} is/are modified
   * @param  {String|Array}   dataName - data(s) to be watched (Array, or space-separated string)
   * @param  {Function} callback - context: this ; arguments: (mixed newValueOfData)
   * @return {void}
   */
  $watch(dataName, callback){
    if ( !Array.isArray(dataName) ) dataName = dataName.trim().split(/\s/);
    for ( let index in dataName ){
      if ( !this._watchers[dataName[index]] ) this._watchers[dataName[index]] = [];
      this._watchers[ dataName[index] ].push( callback.bind(this) );
    }
  },


  /**
   * Returns a simple Object representing the data.
   * @param {Object} options - options for returned object:
   *  - {Boolean} computed - either to include computed properties
   *  - {Array|String} only - specifies to return only listed properties (They may be listed in an array, or a space-separated string)
   *  - {Array|String} except - specifies to *not* return listed properties (They may be listed in an array, or a space-separated string)
   * @return {Object}
   */
  toHash(options={}){
    let self = this,
        selection = Object.assign({}, this.$data),
        props;

    if ( options.computed ){
      _each(this.$computed, (callback, name)=>{selection[name] = self[name]})
    }

    if ( options.only ){
      props = options.only;
      props = Array.isArray( props ) ? props : props.trim().split(/\s+/);
      _each(selection, (val, prop)=>{
        if ( props.indexOf(prop) == -1 ) delete selection[prop];
      });
    }

    if ( options.except ){
      props = options.except;
      props = Array.isArray( props ) ? props : props.trim().split(/\s+/);
      _each(selection, (val, prop)=>{
        if ( props.indexOf(prop) > -1 ) delete selection[prop];
      });
    }

    return JSON.parse( JSON.stringify( selection ) );
  }
}

let Model = {
  /**
   * Returns a new model constructor
   * @param  {Object} desc - Model description (data, methods, watch, etc.)
   * @return {Function} - a new model constructor
   */
  extend( desc={} ){
    function initializer(data){

      let keepAside;

      // prepare watchers
      keepAside = this._watchers;
      this._watchers = {};
      _each(keepAside, function(callback, pattern){
        this.$watch(pattern, callback);
      }.bind(this));

      // Prepare handling of computed properties
      this._computers={}; // properties used for computation of each computed property
      this._computing=[]; // computed properties currently being computed

      // merge prototype data with instance data
      keepAside = this.$data();
      this.$data = {};
      _each(Object.assign({}, keepAside, data), function(val, key){
        this.$set(key, val);
      }.bind(this));

      // Initialize computed properties.
      for ( let name in this.$computed) this.$compute(name);

      this.created();
    }

    let proto = initializer.prototype;

    _each(modelProto, (val, prop)=>{proto[prop]=val;});

    let tpm = proto.$data || function(){};
    proto.$data = function(){
      let fromDesc = desc.data ? desc.data() : {};
      let fromProto = tpm() || {};
      return Object.assign({}, fromDesc, fromProto)
    }

    proto._watchers = proto._watchers || {};
    Object.assign(proto._watchers, desc.watch);

    proto.$computed = proto.$computed || {};
    Object.assign(proto.$computed, desc.computed)

    "created".split(' ').forEach(name=>proto[name]=desc[name]);

    Object.assign(proto, desc.methods)

    return initializer;
  }
}


export default Model;
