<template lang="html">
  <base-game-layout v-if="trainingExpr && choices.length">

    <template slot="title">Mix Letters</template>

    <p>
      What is the proper spelling for <strong>{{ aRandomTranslation }}</strong> ?
    </p>

    <div class="space-under">
      <button
        v-for="choice in choices"
        type="button"
        class="button space-right space-under-small"
        @click="check(choice)"
        :class="choiceClasses(choice)"
      >
        {{ choice }}
      </button>
    </div>

    <game-result :result="result" />

  </base-game-layout>
</template>

<script>
import BaseGame from "./BaseGame.vue";
import BaseGameLayout from "./BaseGameLayout.vue";
import GameResult from "../GameResult.vue";

export default {

  name: "mix-letters-game",
  extends: BaseGame,

  props: {
    thesaurus: {
      default: [],
    }
  }, // props

  components: {
    BaseGameLayout, GameResult
  },

  data(){
    return {
      choices: [],
      trainingExpr: null,
      lastTrainedExpr: null,

      selected: null, // selected translation
      result: 0, // -1=failure ; 0=none ; 1=success
    }
  }, // data

  computed: {
    aRandomTranslation(){
      return this.trainingExpr.translations.sort( (a,b)=>Math.random()-0.5 )[0];
    }
  },

  methods: {
    check(choice){
      if ( !this.selected ){
        let success = choice == this.trainingExpr.text;
        this.trainingExpr.train( success );
        this.result = success ? 1 : -1;
        this.selected = choice;
        setTimeout(this.replay, 800);
      }
    },

    choiceClasses( choice ){
      let classes = [];
      if ( this.result ) classes.push( choice == this.result>0 ? 'is-success' : 'is-danger' )
      if ( this.selected == choice ) classes.push( 'selected-answer' );
      return classes;
    },

    mix(expr){
      let mix = expr.text;
      while ( mix == expr.text ) mix = expr.text.split('').sort( (a,b)=>Math.random()-0.5 ).join('');
      return mix;
    }
  }, // methods

  created(){
    this.trainExpr( this.pickExpr() );
    let nbWrongChoices = 3;
    while (nbWrongChoices--) this.choices.push( this.mix( this.trainingExpr ) );
    this.choices.push( this.trainingExpr.text );
    this.choices.sort( (a,b)=>Math.random()-0.5 );
  }
}
</script>
