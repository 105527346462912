<template lang="html">
  <div style="position:relative">

    <div class="buttons">
      <button href="#" class="button" @click.prevent="close">close <span class="enticon">&times;</span></button>
      <!-- <button href="#" class="button" @click.prevent="replay">replay <span class="enticon">&#10226;</span></button> -->
    </div>

    <h3>
      <slot name="title">Base Game</slot>
    </h3>

    <slot>Play here</slot>

  </div>
</template>

<style media="screen">
  .buttons {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>

<script>
export default {
  methods: {
    /**
     * Restarts the same game
     * @return {[type]} [description]
     */
    replay(){
      this.$emit('replay');
    },

    /**
     * Closes the game
     * @return {[type]} [description]
     */
    close(){
      this.$parent.close();
    },

    /**
     * Replays the game
     * @return {[type]} [description]
     */
    replay(){
      this.$parent.replay();
    }
  }
}
</script>
